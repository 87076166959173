import React from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Footer() {
  return (
    <footer className="m-6" data-aos="zoom-up" data-aos-anchor-placement="top-bottom">
      <div className="row justify-between">
        <div>
          <small>&copy; {new Date().getFullYear()}. All Right Reserved.</small>
        </div>
        <div>
          <small>
            Developed with by <Link to="/">Aditya Dave</Link>. Designed by{' '}
            <a href="https://www.ahamadroit.com" target="_blank" rel="noreferrer">
              AhamAdroit
            </a>
            .
          </small>
        </div>
      </div>
    </footer>
  );
}
