import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header/header.component';
import Footer from '../components/footer/footer.component';

interface BaseProps extends React.HTMLAttributes<Element> {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Base = ({ children }: BaseProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

Base.propTypes = {
  children: PropTypes.node.isRequired
};

export default Base;
