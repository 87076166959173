import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --accent-color: #ff4f00;
    --primary-color: #54463a;
  }

  body {
    margin: 0;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .home-hero {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(to right, white 50%,#54463a 50%);
    border: none;

    .bg-half-white {
      background-image: linear-gradient(white, white), linear-gradient(#e6e6e6, #e6e6e6);
      background-size: calc(1 * (100% / 3)) 100%, calc(4 * (100% / 4)) 100%;
      background-repeat: no-repeat;
    }

    h1 {
      font: normal normal bold 180px/219px Montserrat;
      color: var(--accent-color);
    }

    .img-container {
      img {
        padding: 6rem;
        width: 100%;
        object-fit: cover;
      }
    }

    @media only screen and (max-width: 640px) {
      h1 {
        font-size: 5rem;
        line-height: 8rem;
      }
    }

    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 5rem;
        line-height: 8rem;
      }
    }

    @media only screen and (min-width: 1024px) {
      h1 {
        font-size: 10rem;
        line-height: 12rem;
      }
    }
  }

  .info-section {
    padding: 4rem 0;

    h3 {
      font: normal normal 600 30px/55px Montserrat;
      letter-spacing: 0px;
    }

    h4 {
      font: normal normal bold 25px/55px Montserrat;
      letter-spacing: 0px;
    }

    p {
      font: normal normal normal 18px/25px Montserrat;
      letter-spacing: 0px;
      margin: 12px 2rem 16px 0;
    }

    small {
      font: normal normal normal 18px/25px Montserrat;
      letter-spacing: 0px;
      color: var(--accent-color);
    }
  }

  .technology-section {
    padding: 4rem 0;

    h3 {
      text-align: left;
      font: normal normal 600 45px/55px Montserrat;
      letter-spacing: 0px;
    }

    .technologies {
      margin-top: 3rem;
      width: 100%;


      .slick-prev:before,
      .slick-next:before {
        color: black;
      }
    }
  }

  .about-section {
    h3 {
      font: normal normal bold 35px/55px Montserrat;
      letter-spacing: 0px;
      margin: 1.5rem 0;
    }
    p {
      margin-bottom: 1rem;
      text-align: left;
    }
    table {
      tr {
        border-bottom: 1px solid #e4e4e4;
      }
      td {
        vertical-align: baseline;
        padding: 1rem;
      }
    }
  }

  .filter-sort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: row;
      align-content: center;

      label {
        font: normal normal bold 25px/55px Montserrat;
        letter-spacing: 0px;
        padding-right: 1.5rem;

        @media only screen and (max-width: 640px) {
          line-height: 2rem;
        }
      }

      select {
        padding: 12px;
        background-color: white;
        border: 1px solid black;
        border-radius: 4px;
      }

      button {
        outline: none;
        display: inline-block;
        width: 26px;
        margin-right: 16px;
      }
    }

    @media only screen and (max-width: 640px) {
      flex-wrap: wrap;

      span:nth-child(2) {
        margin-top: 2rem;
      }
    }
  }
`;

export default GlobalStyle;
