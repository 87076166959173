/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

interface IAppContextValues {
  menuOpen: boolean;
}

export const AppContextValues: IAppContextValues = {
  menuOpen: false
};

const appVal = {
  appContext: {
    menuOpen: true
  },
  setAppContext: (appContext: IAppContextValues): void => {}
};

const AppContext = React.createContext(appVal);

export default AppContext;
