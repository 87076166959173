import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .lps-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      border: 4px solid var(--accent-color);
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    div:nth-child(2) {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Preloader = () => {
  return (
    <Container>
      <div className="lps-ripple">
        <div />
        <div />
      </div>
    </Container>
  );
};

export default Preloader;
