import styled from 'styled-components';

interface IMenuProps {
  open: boolean;
}

export const MenuButton = styled('div')<IMenuProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin-right: 12px;
  z-index: 1001;

  ${(props) =>
    props.open
      ? `

      span {
        margin-bottom: 0;
        background-color: ${props.theme.menuBtn.openColor}
      }

      span:nth-child(2) {
        background-color: unset;
        border-left: 8px solid ${props.theme.menuBtn.openColor};
        border-right: 22px solid ${props.theme.menuBtn.openColor};
      }

      span:nth-child(1) {
        opacity: 0;
      }
      
      span:nth-child(2) {
        transform: rotate(45deg);
        margin-bottom: -3px;
      }
      
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
  `
      : ``}
`;

export const MenuBar = styled.span`
  display: inline-block;
  width: 32px;
  height: 0.2em;
  margin-bottom: 6px;
  background-color: ${(props) => props.theme.menuBtn.defaultColor};
  border-radius: 8px;
  transition-property: transform, margin, opacity, background-color, border-color;
  transition-duration: 600ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:nth-child(2) {
    background-color: unset;
    border-left: 8px solid ${(props) => props.theme.menuBtn.defaultColor};
    border-right: 22px solid ${(props) => props.theme.menuBtn.defaultColor};
  }
`;

export const Menu = styled('nav')<IMenuProps>`
  display: block;
  opacity: ${(props) => (props.open ? 1 : 0)};
  height: ${(props) => (props.open ? 'auto' : 0)};
  max-height: 100vh;
  overflow-y: auto;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  margin: 0.5rem;
  padding: 2rem 4rem;
  border: 0.5rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

  @media only screen and (min-width: 1024px) {
    margin: 1rem 1.5rem 0.5rem 0.5rem;
  }
`;

export const MenuContainer = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 1.8rem;

  li:last-child {
    color: var(--accent-color);
  }
`;

export const MenuItem = styled.li`
  padding: 0.8rem 0;
`;
