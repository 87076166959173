import { Contact } from "../types/contact.d";

const CONTACT_DETAILS: Contact = {
  name: "Aditya Dave",
  role: "Full-stack Developer",
  mobile: "+1-(562)-583-8455",
  skype: "aditya.dave2011",
  email: "connect@adityadave.dev",
  resume:
    "https://drive.google.com/file/d/1ZmJ2OIQ1OePqf6kei-RuBD4GpJxiVkQb/view?usp=drive_link",
  linkedin: "https://www.linkedin.com/in/daveaditya/",
  twitter: "https://www.twitter.com/adityadave_dev",
  github: "https://www.github.com/daveaditya",
  bitbucket: "https://bitbucket.org/daveaditya/",
  kaggle: "https://www.kaggle.com/daveaditya",
};

export default CONTACT_DETAILS;
