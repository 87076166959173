import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AOS from 'aos';

import Routers from './routers';
import Base from './layouts/base';

import './App.scss';
import AppContext, { AppContextValues } from './contexts/app.context';

export const DefaultTheme = {
  menuBtn: {
    defaultColor: 'white',
    scrolledColor: 'var(--primary-color)',
    openColor: 'var(--primary-color)'
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  const location = useLocation();
  // const navigation = useNavigation();

  const [appContext, setAppContext] = useState(AppContextValues);
  const [theme, setTheme] = useState(DefaultTheme);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight - 150) {
      setTheme({
        menuBtn: {
          ...theme.menuBtn,
          defaultColor: 'var(--primary-color)'
        }
      });
    } else {
      setTheme({
        menuBtn: {
          ...theme.menuBtn,
          defaultColor: 'white'
        }
      });
    }
  };

  const closeMenu = () => setAppContext({ menuOpen: false });

  const escFunction = (event: { keyCode: number }) => {
    if (event.keyCode === 27) {
      closeMenu();
    }
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    // Add escape handler
    window.addEventListener('keydown', escFunction);

    // Check if the home page, then set the color for navigation button
    switch (location.pathname) {
      case '/':
        if (window.scrollY > window.innerHeight - 150) {
          setTheme({
            menuBtn: {
              ...theme.menuBtn,
              defaultColor: 'var(--primary-color)'
            }
          });
        } else {
          setTheme({
            menuBtn: {
              ...theme.menuBtn,
              defaultColor: 'white'
            }
          });
        }
        window.addEventListener('scroll', handleScroll);
        break;
      default:
        window.removeEventListener('scroll', handleScroll);
        setTheme({
          menuBtn: {
            ...theme.menuBtn,
            defaultColor: 'var(--primary-color)'
          }
        });
    }

    return function cleanup() {
      window.removeEventListener('keydown', escFunction);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <Base>
          <Routers />
        </Base>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
