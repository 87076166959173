import { lazy } from 'react';

const AboutMe = lazy(() => import('./about-me.page'));
const ContactMe = lazy(() => import('./contact-me.page'));
const Home = lazy(() => import('./home.page'));
const Projects = lazy(() => import('./projects.page'));
const Publications = lazy(() => import('./publications.page'));
const Research = lazy(() => import('./research.page'));
const NotFound = lazy(() => import('./errors/not-found.page'));

const Errors = {
  NotFound
};

export { AboutMe, ContactMe, Home, Projects, Publications, Research, Errors };
