import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AppContext from '../../contexts/app.context';
import { MenuButton, MenuBar, Menu, MenuContainer, MenuItem } from './menu.component';

import CONTACT_DETAILS from '../../data/contact.data';

const StyledHeader = styled.header`
  position: fixed;
  display: block;
  width: 100vw;
  z-index: 1;
`;

const Logo = styled.img`
  width: 80%;
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Header() {
  const { appContext, setAppContext } = useContext(AppContext);

  const toggleMenu = () => setAppContext({ menuOpen: !appContext.menuOpen });

  return (
    <StyledHeader className="p-7">
      <div className="row justify-between items-center">
        <div className="logo">
          <Link to="/">
            <Logo src="/images/logo.svg" alt="Logo" />
          </Link>
        </div>
        <MenuButton open={appContext.menuOpen} onClick={toggleMenu}>
          <MenuBar />
          <MenuBar />
          <MenuBar />
        </MenuButton>
      </div>
      <Menu open={appContext.menuOpen}>
        <MenuContainer>
          <MenuItem>
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/about-me" onClick={toggleMenu}>
              About Me
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/projects" onClick={toggleMenu}>
              Projects
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/research" onClick={toggleMenu}>
              Research
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link to="/publications" onClick={toggleMenu}>
              Publications
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link to="/contact-me" onClick={toggleMenu}>
              Contact Me
            </Link>
          </MenuItem>
          <MenuItem>
            <a href={CONTACT_DETAILS.resume} target="_blank" rel="noreferrer" onClick={toggleMenu}>
              Resume{' '}
              <sup>
                <small>
                  <i className="fas fa-external-link-alt" />
                </small>
              </sup>
            </a>
          </MenuItem>
        </MenuContainer>
      </Menu>
    </StyledHeader>
  );
}
