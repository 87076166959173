import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Preloader from '../components/preloader/preloader';
import * as Pages from '../pages';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Routers = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Preloader />
        </div>
      }>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/about-me" element={<Pages.AboutMe />} />
        <Route path="/research" element={<Pages.Research />} />
        {/* <Route path="/publications" element={<Pages.Publications/>} /> */}
        <Route path="/projects" element={<Pages.Projects />} />
        <Route path="/contact-me" element={<Pages.ContactMe />} />
        <Route path="/*" element={<Pages.Errors.NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
